























import Vue from "vue";

export default Vue.extend({
  name: "ProjectExportBox",
  props: {
    project: { type: Object, required: true },
  },
  methods: {
    async exportBookings() {
      function leftPad(number: number): string {
        const string = String(number);
        if (string.length < 2) {
          return `0${string}`;
        }
        return string;
      }

      try {
        // Get formatted file content from the server
        const exportData = await this.$store.dispatch("exportBookings", {
          projectId: this.project.id,
          format: "csv",
          type: "project-bookings",
        });
        const blob = new Blob([exportData], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Construct file name with today's date
        const date = new Date();
        const datePrefix = `${date.getFullYear()}-${leftPad(
          date.getMonth() + 1,
        )}-${leftPad(date.getDate())}`;
        link.download = this.$store.getters["isInternalProject"](
          this.project.id,
        )
          ? `${datePrefix}_${this.project.pid}.csv`
          : `${datePrefix}_PID${this.project.pid}.csv`;

        // Trigger file download
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        alert(`Fehler beim Export: ${(e as Error).message || e || ""}`);
        console.error(e);
      }
    },
  },
});
