var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('FeathersVuexGet',{attrs:{"id":_vm.projectId,"service":"projects","watch":['id']},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var project = ref.item;
var isGetPending = ref.isGetPending;
return [(isGetPending)?_c('LoadingSpinner'):(project)?_c('section',[_c('h1',[_vm._v(_vm._s(project.name))]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-9 mb-3"},[_c('h2',[_vm._v("Buchungen")]),_c('BookingList',{attrs:{"project-id":_vm.projectId}})],1),_c('div',{staticClass:"col-lg-3"},[_c('div',{staticClass:"card border-0 mb-3",staticStyle:{"background":"unset"}},[_c('div',{staticClass:"btn-group-vertical",attrs:{"role":"group","aria-label":"Schaltflächen für Transaktionen"}},[_c('RouterLink',{staticClass:"btn btn-primary text-nowrap",attrs:{"to":{
                  name: 'payments',
                  query: {
                    sourceProjectId: _vm.projectId,
                    targetProjectId: _vm.isInternalProject
                      ? 0
                      : _vm.$store.state.ioPid,
                  },
                }}},[_c('span',{staticClass:"bi bi-send",attrs:{"aria-hidden":"true"}}),_vm._v(" Überweisen ")]),(_vm.isInternalProject)?_c('RouterLink',{staticClass:"btn btn-outline-secondary text-nowrap",attrs:{"to":{
                  name: 'payments',
                  query: {
                    sourceProjectId: 0,
                    targetProjectId: _vm.projectId,
                    description: 'Einzahlung: Rechnungsnummer ',
                  },
                }}},[_c('span',{staticClass:"bi bi-download",attrs:{"aria-hidden":"true"}}),_vm._v(" Einzahlen ")]):_vm._e(),(_vm.isInternalProject)?_c('RouterLink',{staticClass:"btn btn-outline-secondary text-nowrap",attrs:{"to":{
                  name: 'payments',
                  query: {
                    sourceProjectId: _vm.projectId,
                    targetProjectId: 0,
                    description: 'Auszahlung: Rechnungsnummer ',
                  },
                }}},[_c('span',{staticClass:"bi bi-upload",attrs:{"aria-hidden":"true"}}),_vm._v(" Auszahlen ")]):_vm._e()],1),(!_vm.isInternalProject)?_c('div',{staticClass:"btn-group-vertical mt-3",attrs:{"role":"group","aria-label":"Schaltflächen zum Bearbeiten des Projekts"}},[_c('RouterLink',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
                  name: 'project-editor',
                  params: { projectId: _vm.projectId },
                }}},[_c('span',{staticClass:"bi bi-pencil-fill",attrs:{"aria-hidden":"true"}}),_vm._v(" Bearbeiten ")])],1):_vm._e()]),_c('ProjectAccountTotalBox',{staticClass:"mb-3",attrs:{"project":project}}),_c('ProjectInfoBox',{staticClass:"mb-3",attrs:{"project":project}}),_c('ProjectMemberBox',{staticClass:"mb-3",attrs:{"members":project.members}}),_c('ProjectExportBox',{attrs:{"project":project}})],1)])]):_c('MessageBox',{attrs:{"message":"Fehler beim Abrufen des Projekts"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }