









import Vue from "vue";
import ProjectBalance from "@/components/ProjectBalance.vue";

export default Vue.extend({
  name: "ProjectAccountTotalBox",
  components: {
    ProjectBalance,
  },
  props: {
    project: { type: Object, required: true },
  },
});
