var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('MessageBox',{attrs:{"message":_vm.formError}}),(_vm.item)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":false,"eager":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var clone = ref.clone;
var save = ref.save;
var reset = ref.reset;
var remove = ref.remove;
return [_c('ProjectForm',{attrs:{"project":clone},on:{"save":function () {
            _vm.$data.formError = null;
            save()
              .then(function (savedItem) { return _vm.$router.push({
                  name: 'project-details',
                  params: { projectId: savedItem.id },
                }); }
              )
              .catch(function (reason) {
                _vm.$data.formError = reason;
              });
          },"remove":function () {
            _vm.$data.formError = null;
            remove()
              .then(function () { return _vm.$router.push({ name: 'projects' }); })
              .catch(function (reason) {
                _vm.$data.formError = reason;
              });
          },"reset":reset,"cancel":function () {
            _vm.$router.back();
          }}})]}}],null,false,3889470521)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }