












import Vue from "vue";
import MemberIcon from "@/components/MemberIcon.vue";

export default Vue.extend({
  name: "MemberTile",
  components: {
    MemberIcon,
  },
  props: {
    member: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 48,
    },
  },
});
