




import Vue from "vue";
import GeneralAmount from "@/components/GeneralAmount.vue";

export default Vue.extend({
  name: "BookingAmount",
  components: { GeneralAmount },
  props: {
    booking: { type: Object, required: true },
    projectId: { type: Number, required: true },
  },
  computed: {
    isBookingOutbound(): boolean {
      return this.projectId === this.booking.sourceProjectId;
    },
    amount(): number {
      return this.isBookingOutbound
        ? this.booking.amount * -1
        : this.booking.amount;
    },
  },
});
