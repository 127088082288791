






















import Vue from "vue";
import VueSimpleSuggest from "vue-simple-suggest/lib";
import "vue-simple-suggest/dist/styles.css";

export default Vue.extend({
  name: "ProjectSelector",
  components: { VueSimpleSuggest },
  props: {
    value: {
      type: Array,
      required: true,
    },
    showNowhereOption: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Projekte suchen",
    },
    clearInputOnBlur: {
      type: Boolean,
      default: false,
    },
    excludedProjectIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      query: "",
      autoCompleteStyle: {
        vueSimpleSuggest: "position-relative",
        inputWrapper: "",
        defaultInput: "form-control",
        suggestions: "position-absolute list-group z-1000",
        suggestItem: "list-group-item",
      },
    };
  },
  methods: {
    async getSuggestionList() {
      const selectedProjectIds = this.value.map((project: any) => project.id);
      const result = await this.$store.dispatch("projects/find", {
        query: {
          $or: [
            { name: { $like: `%${this.query}%` } },
            { pid: { $like: `%${this.query}%` } },
          ],
          id: { $nin: selectedProjectIds.concat(this.excludedProjectIds) },
          $sort: { pid: 1 },
        },
      });
      const suggestions = result.data || [];

      if (!this.isSelected(0)) {
        suggestions.unshift({
          id: 0,
          name: "[NOWHERE]",
        });
      }

      return suggestions;
    },
    projectSelected(project: Record<string, unknown>) {
      if (!this.isSelected(project.id as number)) {
        this.$emit("input", this.value.concat([project]));
      }
    },
    isSelected(project: number | { id: number }): boolean {
      return this.value
        .map((project: any) => project.id)
        .includes(typeof project === "number" ? project : project.id);
    },
  },
});
