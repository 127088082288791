












import Vue from "vue";
import GeneralAmount from "@/components/GeneralAmount.vue";

export default Vue.extend({
  name: "StatsGridCard",
  components: { GeneralAmount },
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    amount: {
      type: Number,
      required: true,
    },
  },
});
