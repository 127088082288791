var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tr',[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(_vm.booking.createdAt.toLocaleString()))]),_c('td',{staticClass:"text-end"},[_c('BookingAmount',{attrs:{"booking":_vm.booking,"project-id":_vm.projectId}})],1),_c('td',[_c('ProjectLabel',{key:("projectLink-" + _vm.projectId),attrs:{"project-id":_vm.otherProjectId,"make-link":true}})],1),_c('td',[(_vm.booking.cancelledByBookingId)?_c('span',{staticClass:"badge text-bg-warning"},[_vm._v(" Storniert ")]):_vm._e(),(_vm.booking.cancellationFor.length)?_c('span',{staticClass:"badge text-bg-warning"},[_vm._v(" Storno ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.booking.description)+" ")]),_c('td',[(_vm.booking.member)?_c('RouterLink',{attrs:{"to":{
        name: 'member-details',
        params: { memberId: _vm.booking.memberId },
      }}},[_vm._v(" "+_vm._s(_vm.booking.member.name)+" ")]):_c('span',[_vm._v("[NONE]")])],1),_c('td',[_c('div',{staticClass:"hstack gap-2"},[_c('RouterLink',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
          name: 'payments',
          query: {
            sourceProjectId: _vm.projectId,
            amount: _vm.booking.amount,
            description: _vm.booking.description,
          },
        },"title":"Weiterleiten"}},[_c('span',{staticClass:"bi bi-reply forward-icon",attrs:{"aria-hidden":"true"}})]),_c('RouterLink',{staticClass:"btn btn-outline-danger",attrs:{"to":{
          name: 'cancel-payment',
          params: { bookingId: _vm.booking.id },
        },"title":"Stornieren","disabled":!_vm.isCancellable}},[_c('span',{staticClass:"bi bi-x-circle",attrs:{"aria-hidden":"true"}})])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }