
































































































import Vue from "vue";
import BookingSummary from "@/components/BookingSummary.vue";
import MessageBox from "@/components/MessageBox.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend({
  name: "CancellationView",
  components: { LoadingSpinner, BookingSummary, MessageBox },
  props: {
    bookingId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      description: "",
      message: null as unknown,
    };
  },
  computed: {
    isPayloadValid(): boolean {
      return this.payload.description !== "";
    },
    payload(): Record<string, number | string> {
      return {
        bookingId: this.bookingId,
        description: this.description,
      };
    },
  },
  methods: {
    async cancelPayment() {
      if (!this.isPayloadValid) {
        return;
      }

      this.message = null;
      try {
        await this.$store.dispatch("cancelPayment", this.payload);
        this.$router.back();
      } catch (error) {
        this.message = { description: error };
      }
    },
    isCancellable(booking: any): boolean {
      return !(booking.cancellationFor.length || booking.cancelledByBookingId);
    },
  },
});
