























import Vue from "vue";

export default Vue.extend({
  name: "ProjectInfoBox",
  props: {
    project: { type: Object, required: true },
  },
});
