








































































































import Vue from "vue";
import ProjectMemberList from "@/components/ProjectMemberList.vue";
import MemberAmount from "@/components/MemberAmount.vue";
import MemberSelector from "@/components/MemberSelector.vue";

export default Vue.extend({
  name: "ProjectForm",
  components: { ProjectMemberList, MemberAmount, MemberSelector },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      memberCopy: [],
    };
  },
  computed: {
    isNewProject(): boolean {
      return this.project.id === undefined;
    },
  },
  created: function () {
    this.initMembers();
  },
  methods: {
    handleSubmit() {
      if (this.isValid()) {
        // eslint-disable-next-line vue/no-mutating-props
        this.project.members = this.memberCopy;
        this.$emit("save");
      } else {
        // TODO show validation result
      }
    },
    isValid() {
      return (
        this.project.name !== "" &&
        this.project.pid !== "" &&
        this.project.fee >= 0 &&
        this.project.fee <= 100
      );
    },
    removeMember(id: number) {
      this.memberCopy.splice(
        this.memberCopy.findIndex((member: any) => member.id === id),
        1,
      );
    },
    initMembers() {
      this.memberCopy = [].concat(this.project.members);
    },
    reset() {
      this.initMembers();
      this.$emit("reset");
    },
  },
});
