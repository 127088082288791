

































































import Vue from "vue";
import MemberIcon from "@/components/MemberIcon.vue";

export default Vue.extend({
  name: "TheNavbar",
  components: {
    MemberIcon,
  },
  data() {
    return {
      showMobileNavigation: false,
    };
  },
  computed: {
    currentUser: function () {
      return this.$store.getters["auth/user"];
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
});
