



































































































































import Vue from "vue";
import MessageBox from "@/components/MessageBox.vue";
import ProjectSelector from "@/components/ProjectSelector.vue";
import GeneralAmount from "@/components/GeneralAmount.vue";
import ProjectLabel from "@/components/ProjectLabel.vue";
import PaymentSuccessMessage from "@/components/PaymentSuccessMessage.vue";
import { padStart } from "lodash";

export default Vue.extend({
  name: "PaymentView",
  components: {
    PaymentSuccessMessage,
    MessageBox,
    ProjectSelector,
    GeneralAmount,
    ProjectLabel,
  },
  props: {
    prepopulatedAmount: {
      type: Number,
      default: 0,
    },
    prepopulatedDescription: {
      type: String,
      default: "",
    },
    prepopulatedSourceProjectId: {
      type: Number,
      default: 0,
    },
    prepopulatedTargetProjectId: {
      type: Number,
      default: 0,
    },
    prepopulatedDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      sourceProjectId: 0,
      targetProjectId: 0,
      amount: 0,
      description: "",
      date: "",
      message: null as unknown,
      usePredefinedSourceProject: true,
      usePredefinedTargetProject: true,
      latestCreatedPayment: null,
    };
  },
  computed: {
    isPayloadValid(): boolean {
      return (
        this.payload.sourceProjectId !== this.payload.targetProjectId &&
        (!!this.payload.sourceProjectId ||
          this.payload.sourceProjectId === 0) &&
        (!!this.payload.targetProjectId ||
          this.payload.targetProjectId === 0) &&
        this.payload.amount > 0
      );
    },
    payload(): Record<string, number | string> {
      return {
        sourceProjectId: this.sourceProjectId,
        targetProjectId: this.targetProjectId,
        amount: this.amount,
        description: this.description,
        date: this.date,
      };
    },
    sourceProjects: {
      get: function (): any[] {
        return [
          {
            id: this.sourceProjectId,
          },
        ];
      },
      set: function (values: any[]) {
        this.sourceProjectId = (values.slice(-1) as any)[0].id;
      },
    },
    targetProjects: {
      get: function (): any[] {
        return [
          {
            id: this.targetProjectId,
          },
        ];
      },
      set: function (values: any[]) {
        this.targetProjectId = (values.slice(-1) as any)[0].id;
      },
    },
  },
  created() {
    this.resetData();
  },
  methods: {
    resetData() {
      Object.assign(this.$data, this.formatPropsForData(this.$props));
    },
    async counterbalanceIo(payment: Record<string, unknown>) {
      const ioProjectId = this.$store.state.ioPid;
      if (payment.targetProjectId !== ioProjectId) {
        return;
      }

      function isToday(date: Date) {
        return (
          date.toISOString().split("T")[0] ===
          new Date().toISOString().split("T")[0]
        );
      }

      this.sourceProjectId = ioProjectId;
      this.targetProjectId = 0;
      this.amount = payment.amount as number;
      this.description = `Auszahlung: ${payment.description}`;

      const createdAt = payment.createdAt as Date;
      if (isToday(createdAt)) {
        this.date = "";
      } else {
        // Construct local date YYYY-MM-DD
        this.date = `${createdAt.getFullYear()}-${padStart(
          String(createdAt.getMonth() + 1),
          2,
          "0",
        )}-${padStart(String(createdAt.getDate()), 2, "0")}`;
      }

      return this.createPayment();
    },
    async createPayment() {
      if (!this.isPayloadValid) {
        return;
      }

      this.latestCreatedPayment = null;
      this.message = null;
      try {
        this.latestCreatedPayment = await this.$store.dispatch(
          "createPayment",
          this.payload,
        );
        this.resetData();
      } catch (error) {
        this.message = { description: error };
      }
    },
    formatPropsForData(props: any) {
      return {
        amount: props.prepopulatedAmount,
        description: props.prepopulatedDescription,
        sourceProjectId: props.prepopulatedSourceProjectId,
        targetProjectId: props.prepopulatedTargetProjectId,
        date: props.prepopulatedDate,
        usePredefinedSourceProject: true,
        usePredefinedTargetProject: true,
      };
    },
  },
});
