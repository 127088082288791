






































import Vue from "vue";
import ProjectGrid from "@/components/ProjectGrid.vue";

export default Vue.extend({
  name: "HomeView",
  components: { ProjectGrid },
  data() {
    return {
      ownProjectIds: [],
    };
  },
  async created() {
    try {
      const projectIds = await this.$store.dispatch(
        "getProjectIdsForMember",
        this.$store.getters["auth/user"].id,
      );
      this.ownProjectIds = projectIds || [];
    } catch (error) {
      console.error("Could not get projects for current member");
    }
  },
});
