


































import Vue from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MessageBox from "@/components/MessageBox.vue";
import StatsGridCard from "@/components/StatsGridCard.vue";

interface StatsData {
  statId: string;
  value: number;
}

export default Vue.extend({
  name: "StatsView",
  components: { StatsGridCard, MessageBox, LoadingSpinner },
  data() {
    return {
      error: null as unknown,
      isFindPending: true,
      reportingDate: "",
      stats: [] as StatsData[],
    };
  },
  computed: {
    feesPid(): number {
      return this.$store.state.feesPid;
    },
    reportingYear(): number {
      return Number.parseInt(this.reportingDate.split("-")[0]);
    },
    statsToDisplay(): any {
      return {
        totalBalance: {
          title: "Gesamtguthaben",
          description:
            "Summe der Salden aller PIDs bis zum Stichtag. Die Sonderkonten (z.B. zur Verrechnung der Entropieumlage) sind nicht enthalten.",
        },
        feesYearToDate: {
          title: "Entropieumlage laufendes Jahr",
          description: `Summe der einbehaltenen Entropieumlage seit Jahresbeginn ${this.reportingYear} bis zum Stichtag.`,
        },
        feesPreviousYear: {
          title: "Entropieumlage vorangegangenes Jahr",
          description: `Summe der einbehaltenen Entropieumlage im gesamten Kalenderjahr ${this.reportingYear - 1}.`,
        },
      };
    },
    today(): string {
      const leftPad = (number: number): string => {
        const string = String(number);
        if (string.length < 2) {
          return `0${string}`;
        }
        return string;
      };

      const date = new Date();
      return `${date.getFullYear()}-${leftPad(date.getMonth() + 1)}-${leftPad(date.getDate())}`;
    },
  },
  async created() {
    this.reportingDate = this.today;
    await this.loadStats();
  },
  methods: {
    getStat(statId: string): number | null {
      const find = this.stats.find(
        (value: StatsData) => value.statId === statId,
      );
      return find ? find.value : null;
    },
    async loadStats() {
      this.isFindPending = true;
      try {
        this.stats = await this.$store.dispatch("getStats", {
          reportingDate: this.reportingDate,
        });
      } catch (e) {
        this.error = e;
      } finally {
        this.isFindPending = false;
      }
    },
  },
});
