
































import Vue from "vue";

export default Vue.extend({
  name: "SortingControls",
  props: {
    value: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sortBy: "",
      sortOrder: 1,
    };
  },
  created() {
    this.sortBy = Object.keys(this.value)[0];
    this.sortOrder = Number(Object.values(this.value)[0]) || 1;
  },
  methods: {
    onChange() {
      this.$emit("input", Object.fromEntries([[this.sortBy, this.sortOrder]]));
    },
  },
});
