

















import Vue from "vue";
import { sha256 } from "js-sha256";

export default Vue.extend({
  name: "MemberIcon",
  props: {
    member: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 32,
    },
  },
  computed: {
    url() {
      const hash = sha256(this.member.email.toLowerCase());
      return `https://seccdn.libravatar.org/avatar/${hash}?d=identicon&s=${this.size}`;
    },
  },
});
