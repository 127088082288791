









































































































































































































































import Vue from "vue";
import { FeathersVuexPagination, useFind } from "feathers-vuex";
import PaginationControls from "@/components/PaginationControls.vue";
import { models } from "@/feathers-client";
import { computed, ref } from "@vue/composition-api";
import BookingListLine from "@/components/BookingListLine.vue";
import _ from "lodash";
import { Query } from "@feathersjs/feathers";
import ProjectSelector from "@/components/ProjectSelector.vue";
import MemberSelector from "@/components/MemberSelector.vue";
import ProjectMemberList from "@/components/ProjectMemberList.vue";
import BookingCard from "@/components/BookingCard.vue";

export default Vue.extend({
  name: "BookingList",
  components: {
    ProjectSelector,
    BookingListLine,
    FeathersVuexPagination,
    PaginationControls,
    MemberSelector,
    ProjectMemberList,
    BookingCard,
  },
  props: {
    projectId: { type: Number, required: true },
  },
  setup(props, context) {
    const { Booking } = models.api;

    const pagination = ref({
      $limit: 15,
      $skip: 0,
    });
    const searchText = ref("");
    const fromDate = ref("");
    const toDate = ref("");
    const direction = ref("both");
    const view = ref("table");
    const recipients = ref([]);
    const recipientIds = computed(() => {
      return recipients.value.map((project: any) => project.id);
    });
    const initiators = ref([]);

    const updateSearchText = _.debounce((event) => {
      searchText.value = event.target.value;
    }, 400);

    // Get list of cancellations, to exclude them from the main query in card view
    const cancellationParams = computed(() => {
      return {
        query: {
          $select: ["id", "cancelledByBookingId"],
          $and: [
            {
              $or: [
                { targetProjectId: props.projectId },
                { sourceProjectId: props.projectId },
              ],
            },
            { $not: { cancelledByBookingId: null } },
          ],
          $limit: 50,
        },
        qid: `projectCancellationBookings-${props.projectId}`,
        paginate: "default",
      };
    });

    const cancellationsData = useFind({
      model: Booking,
      params: cancellationParams,
    });
    const { items: cancellations } = cancellationsData;

    const params = computed(() => {
      const query = {
        $and: [],
        $sort: { createdAt: -1, id: -1 },
        $eager:
          "[source, target, member, fees.cancellation.member, cancellation.member, cancellationFor]",
      } as Query;

      // Filter out cancellations and fees in card view
      if (view.value !== "table") {
        query.$and.push({
          id: {
            $nin: cancellations.value.map(
              (booking: any) => booking.cancelledByBookingId,
            ),
          },
        });

        // For everything else than the Fees project, show only non-fees
        if (props.projectId !== context.root.$store.state.feesPid) {
          query.$and.push({ feeForBookingId: { $eq: null } });
        }
      }

      if (searchText.value !== "") {
        query.$and.push({ description: { $like: `%${searchText.value}%` } });
      }
      if (fromDate.value !== "") {
        query.$and.push({
          createdAt: { $gte: new Date(`${fromDate.value}T00:00:00`) },
        });
      }
      if (toDate.value !== "") {
        query.$and.push({
          createdAt: { $lte: new Date(`${toDate.value}T23:59:59`) },
        });
      }

      if (direction.value === "from") {
        if (recipients.value.length > 0) {
          const fromCondition = {
            $or: [
              {
                sourceProjectId: {
                  $in: recipientIds.value,
                },
              },
            ],
          } as any;
          if (recipientIds.value.includes(0)) {
            fromCondition.$or.push({ sourceProjectId: { $eq: null } });
          }
          query.$and.push(fromCondition);
        }
        query.$and.push({ targetProjectId: props.projectId });
      } else if (direction.value === "to") {
        if (recipients.value.length > 0) {
          const toCondition = {
            $or: [
              {
                targetProjectId: {
                  $in: recipientIds.value,
                },
              },
            ],
          } as any;
          if (recipientIds.value.includes(0)) {
            toCondition.$or.push({ targetProjectId: { $eq: null } });
          }
          query.$and.push(toCondition);
        }
        query.$and.push({ sourceProjectId: props.projectId });
      } else if (direction.value === "both") {
        query.$and.push({
          $or: [
            { targetProjectId: props.projectId },
            { sourceProjectId: props.projectId },
          ],
        });

        if (recipients.value.length > 0) {
          const bothCondition = {
            $or: [
              {
                targetProjectId: {
                  $in: recipientIds.value,
                },
              },
              {
                sourceProjectId: {
                  $in: recipientIds.value,
                },
              },
            ],
          } as any;
          if (recipientIds.value.includes(0)) {
            bothCondition.$or.push({ sourceProjectId: { $eq: null } });
            bothCondition.$or.push({ targetProjectId: { $eq: null } });
          }
          query.$and.push(bothCondition);
        }
      }

      if (initiators.value.length > 0) {
        query.$and.push({
          memberId: {
            $in: initiators.value.map((member: any) => member.id),
          },
        });
      }

      Object.assign(query, pagination.value);
      return {
        query,
        qid: `projectBookings-${props.projectId}`,
        paginate: "default",
      };
    });

    const data = useFind({ model: Booking, params: params });
    const { items: bookings, latestQuery } = data;

    const removeMember = (id: number) => {
      initiators.value.splice(
        initiators.value.findIndex((member: any) => member.id === id),
        1,
      );
    };

    const removeProject = (id: number) => {
      recipients.value.splice(
        recipients.value.findIndex((project: any) => project.id === id),
        1,
      );
    };

    return {
      bookings,
      cancellations,
      pagination,
      latestQuery,
      updateSearchText,
      searchText,
      fromDate,
      toDate,
      recipients,
      recipientIds,
      direction,
      initiators,
      removeMember,
      removeProject,
      view,
    };
  },
});
