

















import LoginView from "@/views/LoginView.vue";
import MessageBox from "@/components/MessageBox.vue";
import TheNavbar from "@/components/TheNavbar.vue";
import Vue from "vue";

export default Vue.extend({
  components: {
    TheNavbar,
    MessageBox,
    LoginView,
  },
  data() {
    return {
      essentialDataLoaded: false,
    };
  },
  computed: {
    fatalError() {
      return this.$store.state.fatalError;
    },
    loggedIn: function () {
      return this.$store.getters["auth/isAuthenticated"];
    },
  },
  created() {
    // Try to authenticate, which populates the auth store. Based on that, the app will ask for a login or not
    this.$store.dispatch("auth/authenticate").catch(() => {
      // No need to worry. This just means the session expired or there was no token in the first place.
    });
  },
  mounted() {
    this.$store.subscribe(async (mutation) => {
      // Wait until the (re)authentication is done, and then load essential data
      if (
        mutation.type === "auth/unsetAuthenticatePending" &&
        this.loggedIn === true
      ) {
        try {
          await this.loadInternalProjects();
        } catch (reason) {
          console.error(reason);
          this.$store.commit(
            "setFatalError",
            new Error(
              "Fehler beim Laden essenzieller Daten, prüfe die Javascript-Konsole für Details.",
            ),
          );
          return;
        }
        this.essentialDataLoaded = true;
      }
    });
  },
  methods: {
    async loadInternalProjects() {
      const internalProjects = await this.$store.dispatch("projects/find", {
        query: { pid: { $like: "WTF%" }, $eager: "members" },
      });

      for (const internalProject of internalProjects.data) {
        if (internalProject.pid === "WTF_IO") {
          await this.$store.commit("setIoPid", internalProject.id);
        } else if (internalProject.pid === "WTF_FEES") {
          await this.$store.commit("setFeesPid", internalProject.id);
        } else {
          console.warn("Unknown special PID %s", internalProject.pid);
        }
      }
    },
  },
});
