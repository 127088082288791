<template>
  <nav :aria-label="ariaLabel">
    <div class="btn-group" role="group">
      <button
        type="button"
        class="btn btn-outline-secondary"
        title="Gehe zur ersten Seite"
        :disabled="!canPrev"
        @click="(e) => $emit('to-start', e)"
      >
        <span class="bi bi-chevron-bar-left" />
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        title="Gehe zur vorherigen Seite"
        :disabled="!canPrev"
        @click="(e) => $emit('prev', e)"
      >
        <span class="bi bi-chevron-left" />
      </button>

      <span class="btn btn-outline-secondary">
        Seite {{ currentPage }} von {{ pageCount }}
      </span>

      <button
        type="button"
        class="btn btn-outline-secondary"
        title="Gehe zur nächsten Seite"
        :disabled="!canNext"
        @click="(e) => $emit('next', e)"
      >
        <span class="bi bi-chevron-right" />
      </button>
      <button
        type="button"
        class="btn btn-outline-secondary"
        title="Gehe zur letzten Seite"
        :disabled="!canNext"
        @click="(e) => $emit('to-end', e)"
      >
        <span class="bi bi-chevron-bar-right" />
      </button>
    </div>
  </nav>
</template>

<script>
export default {
  name: "PaginationControls",
  props: {
    ariaLabel: {
      type: String,
      default: "Paginierung",
    },
    currentPage: {
      type: Number,
      required: true,
    },
    pageCount: {
      type: Number,
      required: true,
    },
    canPrev: {
      type: Boolean,
      required: true,
    },
    canNext: {
      type: Boolean,
      required: true,
    },
  },
};
</script>
