




































































































import Vue from "vue";
import MessageBox from "@/components/MessageBox.vue";
import ProjectInfoBox from "@/components/ProjectInfoBox.vue";
import ProjectMemberBox from "@/components/ProjectMemberBox.vue";
import BookingList from "@/components/BookingList.vue";
import ProjectAccountTotalBox from "@/components/ProjectAccountTotalBox.vue";
import ProjectExportBox from "@/components/ProjectExportBox.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend({
  name: "ProjectDetailView",
  components: {
    ProjectExportBox,
    ProjectAccountTotalBox,
    BookingList,
    MessageBox,
    ProjectInfoBox,
    ProjectMemberBox,
    LoadingSpinner,
  },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isInternalProject() {
      return this.$store.getters["isInternalProject"](this.projectId);
    },
  },
});
