var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"mb-4"},[_vm._v("Buchung stornieren")]),(_vm.message)?_c('MessageBox',{attrs:{"message":_vm.message.description,"color":_vm.message.color || ''}}):_vm._e(),_c('FeathersVuexFind',{attrs:{"service":"bookings","params":{
      query: {
        id: _vm.bookingId,
        $eager:
          '[source, target, member, fees, cancellation, cancellationFor]',
      },
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var bookings = ref.items;
    var isFindPending = ref.isFindPending;
return [(isFindPending)?_c('LoadingSpinner'):(!bookings[0])?_c('MessageBox',{attrs:{"message":"Buchung konnte nicht geladen werden"}}):(bookings[0] && !_vm.isCancellable(bookings[0]))?_c('MessageBox',{attrs:{"message":"Stornierte Buchungen oder Storno-Buchungen können nicht storniert werden ;-)"}}):_c('section',[_c('div',{staticClass:"alert alert-warning",attrs:{"role":"alert"}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Soll die folgende Buchung vom "+_vm._s(bookings[0].createdAt.toLocaleDateString())+" storniert werden? ")]),_c('p',{staticClass:"pt-2"},[_c('BookingSummary',{attrs:{"booking":bookings[0]}})],1),_c('FeathersVuexFind',{attrs:{"service":"bookings","params":{ query: { feeForBookingId: _vm.bookingId } },"qid":"feesToCancel","local":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var fees = ref.items;
return [(fees.length)?_c('div',[_c('hr'),_c('p',{staticClass:"mb-2"},[_vm._v(" Die folgenden Gebühren werden ebenfalls storniert: ")]),_c('ul',_vm._l((fees),function(fee){return _c('li',{key:fee.id},[_c('BookingSummary',{attrs:{"booking":fee}})],1)}),0)]):_vm._e()]}}])})],1),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.cancelPayment.apply(null, arguments)}}},[_c('label',{staticClass:"form-label",attrs:{"for":"cancellationReason"}},[_vm._v("Grund für Storno")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.description),expression:"description"}],staticClass:"form-control",attrs:{"id":"cancellationReason","type":"text","aria-describedby":"reasonHelpBlock"},domProps:{"value":(_vm.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.description=$event.target.value}}}),_c('div',{staticClass:"form-text",attrs:{"id":"reasonHelpBlock"}},[_vm._v(" Aus welchem Grund muss die Buchung storniert werden? ")]),_c('div',{staticClass:"hstack gap-2 mt-4"},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.$router.back()}}},[_vm._v(" Zurück ")]),_c('button',{staticClass:"btn btn-danger ms-auto",attrs:{"type":"submit","disabled":!_vm.isPayloadValid}},[_vm._v(" Stornieren ")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }