var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('span',{staticClass:"text-muted me-1"},[_vm._v(_vm._s(_vm.project.pid)+":")]),_c('RouterLink',{staticClass:"stretched-link fw-bold text-decoration-none text-reset",attrs:{"to":{ name: 'project-details', params: { projectId: _vm.project.id } }}},[_vm._v(_vm._s(_vm.project.name)+" ")])],1),_c('div',{staticClass:"card-body"},[_c('span',{staticClass:"fst-italic"},[_vm._v(" "+_vm._s(_vm.project.description || "Keine Beschreibung")+" ")])]),(_vm.showLatestBookings > 0)?[_c('div',{staticClass:"card-header card-footer"},[_vm._v("Letzte Buchungen")]),_c('FeathersVuexFind',{attrs:{"service":"bookings","params":{
          query: {
            $or: [
              { sourceProjectId: _vm.project.id },
              { targetProjectId: _vm.project.id } ],
            $limit: _vm.showLatestBookings,
            $sort: { createdAt: -1 },
            $eager: '[source, target, member, cancellationFor]',
          },
        },"watch":"params"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var bookings = ref.items;
return [_c('ul',{staticClass:"list-group list-group-flush"},[(bookings.length === 0)?_c('li',{staticClass:"list-group-item text-muted fst-italic"},[_vm._v(" Keine Buchungen vorhanden. ")]):_vm._e(),_vm._l((bookings),function(booking){return _c('li',{key:booking.id,staticClass:"list-group-item container"},[_c('div',{staticClass:"hstack"},[_c('div',{staticClass:"vstack"},[_c('div',[_c('ProjectLabel',{staticClass:"stretched-link fw-bold text-decoration-none text-reset",attrs:{"project-id":booking.getOppositeProjectId(_vm.project.id),"make-link":true}})],1),_c('div',{staticClass:"text-muted fst-italic"},[_vm._v(" "+_vm._s(booking.description)+" ")])]),_c('div',{staticClass:"text-end"},[(booking.member)?_c('MemberIcon',{attrs:{"member":booking.member,"size":32}}):_vm._e()],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-sm-6"},[_vm._v(" "+_vm._s(booking.createdAt.toLocaleDateString())+" "),(booking.cancelledByBookingId)?_c('span',{staticClass:"badge text-bg-warning"},[_vm._v(" Storniert ")]):_vm._e(),(booking.cancellationFor.length)?_c('span',{staticClass:"badge text-bg-warning"},[_vm._v(" Storno ")]):_vm._e()]),_c('div',{staticClass:"col col-sm-6 text-end"},[_c('BookingAmount',{attrs:{"booking":booking,"project-id":_vm.project.id}})],1)])])})],2)]}}],null,false,3603019625)})]:_vm._e(),_c('div',{staticClass:"card-footer hstack"},[_c('MemberAmount',{staticClass:"text-muted",attrs:{"amount":_vm.project.members.length}}),(!_vm.$store.getters['isInternalProject'](_vm.project.id))?_c('div',{staticClass:"text-muted ms-auto",attrs:{"title":"Entropieumlage"}},[_vm._v(" "+_vm._s(_vm.project.fee)+" % ")]):_vm._e(),_c('ProjectBalance',{staticClass:"ms-auto",attrs:{"project-id":_vm.project.id}})],1)],2)])}
var staticRenderFns = []

export { render, staticRenderFns }