import Vue from "vue";
import Vuex from "vuex";
import { FeathersVuex } from "feathers-vuex";
import auth from "./store.auth";
import feathersClient from "@/feathers-client";
import memberships from "@/store/modules/memberships";

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context("./services", false, /\.ts$/);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {
    fatalError: null,
    feesPid: null,
    ioPid: null,
  },
  getters: {
    isInternalProject: (state) => (projectId: any) => {
      return [state.ioPid, state.feesPid].includes(projectId);
    },
  },
  mutations: {
    setFatalError(state, value) {
      state.fatalError = value;
    },
    setFeesPid(state, value) {
      state.feesPid = value;
    },
    setIoPid(state, value) {
      state.ioPid = value;
    },
  },
  actions: {
    cancelPayment(context, { bookingId, description }) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "create",
          "payment-cancellation",
          {
            bookingId,
            description,
          },
          (error: never, data: Record<string, unknown>) => {
            if (error) {
              reject(error);
              return;
            }

            // convert date strings to Date instances
            for (const prop of ["createdAt", "updatedAt"]) {
              if (data[prop]) {
                data[prop] = new Date(data[prop] as string);
              }
            }
            resolve(data);
          },
        );
      });
    },
    createPayment(
      context,
      { amount, date, description, sourceProjectId, targetProjectId },
    ) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "create",
          "payments",
          {
            amount,
            date: date ? date : undefined,
            description,
            sourceProjectId,
            targetProjectId,
          },
          (error: never, data: Record<string, unknown>) => {
            if (error) {
              reject(error);
              return;
            }

            // convert date strings to Date instances
            for (const prop of ["createdAt", "updatedAt"]) {
              if (data[prop]) {
                data[prop] = new Date(data[prop] as string);
              }
            }
            resolve(data);
          },
        );
      });
    },
    exportBookings(context, { projectId, format, type }) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "find",
          "export",
          { projectId, format, type },
          (error: never, data: never) => {
            if (error) {
              reject(error);
              return;
            }

            resolve(data);
          },
        );
      });
    },
    getProjectBalance(context, { projectId, reportingDate }) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "get",
          "balances",
          projectId,
          { reportingDate },
          (error: never, data: never) => {
            if (error) {
              reject(error);
              return;
            }

            resolve(data);
          },
        );
      });
    },
    getProjectIdsForMember(context, memberId) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "find",
          "memberships",
          {
            memberId: memberId,
          },
          (error: never, data: Array<Record<string, unknown>>) => {
            if (error) {
              reject(error);
              return;
            }
            resolve(data.map((membership) => membership.projectId));
          },
        );
      });
    },
    getStats(context, { reportingDate }) {
      return new Promise((resolve, reject) => {
        feathersClient.io.emit(
          "find",
          "stats",
          { reportingDate },
          (error: never, data: never) => {
            if (error) {
              reject(error);
              return;
            }

            resolve(data);
          },
        );
      });
    },
  },
  modules: { memberships },
  plugins: [...servicePlugins, auth],
});

export interface RootState {
  fatalError: unknown;
  feesPid: number | null;
  ioPid: number | null;
}
