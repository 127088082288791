













































































































































































import { FeathersVuexPagination, useFind } from "feathers-vuex";
import PaginationControls from "@/components/PaginationControls.vue";
import { computed, ref } from "@vue/composition-api";
import { models } from "@/feathers-client";
import ProjectGrid from "@/components/ProjectGrid.vue";
import SortingControls from "@/components/SortingControls.vue";
import Vue from "vue";
import _ from "lodash";
import ProjectTable from "@/components/ProjectTable.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import MessageBox from "@/components/MessageBox.vue";

export default Vue.extend({
  name: "ProjectsView",
  components: {
    MessageBox,
    LoadingSpinner,
    ProjectTable,
    SortingControls,
    FeathersVuexPagination,
    PaginationControls,
    ProjectGrid,
  },
  setup() {
    const { Project } = models.api;

    const pagination = ref({
      $limit: 15,
      $skip: 0,
    });
    const searchText = ref("");
    const sortObject = ref({ pid: 1 });

    const updateSearchText = _.debounce((event) => {
      searchText.value = event.target.value;
    }, 400);

    const params = computed(() => {
      const query = {
        $sort: sortObject.value,
        $eager: "members",
      };
      if (searchText.value !== "") {
        Object.assign(query, {
          $or: [
            { pid: { $like: `%${searchText.value}%` } },
            { name: { $like: `%${searchText.value}%` } },
            { description: { $like: `%${searchText.value}%` } },
          ],
        });
      }
      Object.assign(query, pagination.value);
      return { query, qid: "projectList", paginate: true };
    });

    const data = useFind({ model: Project, params: params });
    const { items: projects, latestQuery, isPending, error } = data;

    return {
      isSearchPending: isPending,
      searchError: error,
      projects,
      pagination,
      searchText,
      sortObject,
      latestQuery,
      updateSearchText,
    };
  },
  data() {
    return {
      view: "cards",
    };
  },
  methods: {
    async exportProjectsOverview() {
      function leftPad(number: number): string {
        const string = String(number);
        if (string.length < 2) {
          return `0${string}`;
        }
        return string;
      }

      try {
        // Get formatted file content from the server
        const exportData = await this.$store.dispatch("exportBookings", {
          format: "csv",
          type: "projects-overview",
        });
        const blob = new Blob([exportData], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);

        // Construct file name with today's date
        const date = new Date();
        const datePrefix = `${date.getFullYear()}-${leftPad(
          date.getMonth() + 1,
        )}-${leftPad(date.getDate())}`;
        link.download = `${datePrefix}_all_projects.csv`;

        // Trigger file download
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        alert(`Fehler beim Export: ${(e as Error).message || e || ""}`);
        console.error(e);
      }
    },
  },
});
