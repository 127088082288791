



























import Vue from "vue";
import MemberIcon from "@/components/MemberIcon.vue";

export default Vue.extend({
  name: "ProjectMemberGrid",
  components: {
    MemberIcon,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
    max: {
      type: Number,
      default: 64,
    },
    size: {
      type: Number,
      default: 32,
    },
    offset: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isMore() {
      const len = this.members.length || 0;
      return len > this.max;
    },
    displayMembers() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TS2339
      return this.isMore
        ? this.members.slice(0, this.max - this.offset)
        : this.members;
    },
    rest() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: TS2339
      return this.isMore ? this.members.length - (this.max + this.offset) : 0;
    },
  },
});
