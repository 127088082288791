














































































































import { FeathersVuexPagination, useFind } from "feathers-vuex";
import PaginationControls from "@/components/PaginationControls.vue";
import SortingControls from "@/components/SortingControls.vue";
import { computed, ref } from "@vue/composition-api";
import { models } from "@/feathers-client";
import Vue from "vue";
import _ from "lodash";
import MemberTable from "@/components/MemberTable.vue";
import MessageBox from "@/components/MessageBox.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend({
  name: "MembersView",
  components: {
    FeathersVuexPagination,
    LoadingSpinner,
    MemberTable,
    MessageBox,
    PaginationControls,
    SortingControls,
  },
  setup() {
    const { Member } = models.api;

    const pagination = ref({
      $limit: 15,
      $skip: 0,
    });
    const searchText = ref("");
    const sortObject = ref({ name: 1 });

    const updateSearchText = _.debounce((event) => {
      searchText.value = event.target.value;
    }, 400);

    const params = computed(() => {
      const query = {
        $sort: sortObject.value,
        $eager: "projects",
      };
      if (searchText.value !== "") {
        Object.assign(query, {
          $or: [
            { name: { $like: `%${searchText.value}%` } },
            { chiffre: { $like: `%${searchText.value}%` } },
          ],
        });
      }
      Object.assign(query, pagination.value);
      return { query, qid: "memberList", paginate: true };
    });

    const data = useFind({ model: Member, params: params });
    const { items: members, latestQuery, isPending, error } = data;

    return {
      isSearchPending: isPending,
      searchError: error,
      members,
      pagination,
      searchText,
      sortObject,
      latestQuery,
      updateSearchText,
    };
  },
});
