var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row row-cols-1 row-cols-xl-4 gap-3 align-content-center"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('div',{staticClass:"input-group"},[_vm._m(1),_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Member filtern","aria-label":"Member filtern","aria-describedby":"search-button-addon"},on:{"input":_vm.updateSearchText}})])]),_c('div',{staticClass:"col"},[_c('SortingControls',{attrs:{"options":{
          chiffre: 'Chiffre',
          name: 'Nickname',
          createdAt: 'Erstellungsdatum',
          updatedAt: 'Änderungsdatum',
        }},model:{value:(_vm.sortObject),callback:function ($$v) {_vm.sortObject=$$v},expression:"sortObject"}})],1)]),_c('FeathersVuexPagination',{staticClass:"m-3",attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentPage = ref.currentPage;
      var pageCount = ref.pageCount;
      var next = ref.next;
      var prev = ref.prev;
      var toPage = ref.toPage;
      var toStart = ref.toStart;
      var toEnd = ref.toEnd;
      var canNext = ref.canNext;
      var canPrev = ref.canPrev;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('PaginationControls',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"next":next,"prev":prev,"page":toPage,"to-start":toStart,"to-end":toEnd}})],1)]}}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}}),(_vm.searchError)?_c('MessageBox',{attrs:{"message":_vm.searchError}}):(_vm.isSearchPending)?_c('LoadingSpinner'):_c('MemberTable',{attrs:{"members":_vm.members,"sort-object":_vm.sortObject}}),_c('FeathersVuexPagination',{staticClass:"m-3",attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentPage = ref.currentPage;
      var pageCount = ref.pageCount;
      var next = ref.next;
      var prev = ref.prev;
      var toPage = ref.toPage;
      var toStart = ref.toStart;
      var toEnd = ref.toEnd;
      var canNext = ref.canNext;
      var canPrev = ref.canPrev;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('PaginationControls',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"next":next,"prev":prev,"page":toPage,"to-start":toStart,"to-end":toEnd}})],1)]}}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2"},[_c('h1',[_vm._v("Member")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"input-group-text",attrs:{"id":"search-button-addon"}},[_c('span',{staticClass:"bi bi-search"})])}]

export { render, staticRenderFns }