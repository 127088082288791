// initial state
import { ActionContext } from "vuex";
import { RootState } from "@/store";

const state = () => ({
  projectIdsByMemberId: new Map<number, number[]>(),
  memberIdsByProjectId: new Map<number, number[]>(),
});

const getters = {
  memberIdsForProjectId: (state: MembershipState) => (projectId: number) => {
    return state.memberIdsByProjectId.get(projectId) || [];
  },
  projectIdsForMemberId: (state: MembershipState) => (memberId: number) => {
    return state.projectIdsByMemberId.get(memberId) || [];
  },
};

const actions = {
  updateProjectMemberships(
    { commit }: ActionContext<MembershipState, RootState>,
    payload: UpdateProjectPayload,
  ) {
    commit("setMemberIdsForProjectId", payload);
  },
  updateMemberMemberships(
    { commit }: ActionContext<MembershipState, RootState>,
    payload: UpdateMemberPayload,
  ) {
    commit("setProjectIdsForMemberId", payload);
  },
};

const mutations = {
  setMemberIdsForProjectId(
    state: MembershipState,
    { projectId, memberIds }: UpdateProjectPayload,
  ) {
    state.memberIdsByProjectId.set(projectId, memberIds);
  },
  setProjectIdsForMemberId(
    state: MembershipState,
    { memberId, projectIds }: UpdateMemberPayload,
  ) {
    state.projectIdsByMemberId.set(memberId, projectIds);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

interface MembershipState {
  projectIdsByMemberId: Map<number, number[]>;
  memberIdsByProjectId: Map<number, number[]>;
}

interface UpdateProjectPayload {
  projectId: number;
  memberIds: number[];
}

interface UpdateMemberPayload {
  memberId: number;
  projectIds: number[];
}
