













import Vue from "vue";
import ProjectMemberGrid from "@/components/ProjectMemberGrid.vue";
import MemberAmount from "@/components/MemberAmount.vue";

export default Vue.extend({
  name: "ProjectMemberBox",
  components: { ProjectMemberGrid, MemberAmount },
  props: {
    members: { type: Array, required: true },
  },
});
