var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border rounded bg-body-tertiary"},[_c('div',{staticClass:"container-fluid g-8"},[_c('div',{staticClass:"row align-items-center justify-content-between"},[_c('div',{staticClass:"col"},[(_vm.booking.member)?_c('RouterLink',{staticClass:"text-decoration-none text-reset",attrs:{"to":{
            name: 'member-details',
            params: { memberId: _vm.booking.member.id },
          }}},[_c('MemberTile',{attrs:{"member":_vm.booking.member,"size":32}})],1):_vm._e()],1),_c('div',{staticClass:"col hstack justify-content-center gap-2"},[_c('RouterLink',{staticClass:"btn btn-outline-secondary",attrs:{"to":{
            name: 'payments',
            query: {
              sourceProjectId: _vm.projectId,
              amount: _vm.booking.amount,
              description: _vm.booking.description,
            },
          },"title":"Weiterleiten"}},[_c('span',{staticClass:"bi bi-reply forward-icon",attrs:{"aria-hidden":"true"}})]),_c('RouterLink',{staticClass:"btn btn-outline-danger",attrs:{"to":{
            name: 'cancel-payment',
            params: { bookingId: _vm.booking.id },
          },"title":"Stornieren","disabled":!_vm.isCancellable}},[_c('span',{staticClass:"bi bi-x-circle",attrs:{"aria-hidden":"true"}})])],1),_c('div',{staticClass:"col text-end"},[_c('abbr',{staticClass:"fw-bold text-decoration-none",attrs:{"title":"Zwischensumme"}},[_vm._v("Σ")]),_vm._v("  "),_c('GeneralAmount',{attrs:{"amount":_vm.totalAmount}})],1)]),_c('div',{staticClass:"row border-top align-items-center justify-content-between",class:{
        'text-decoration-line-through': !!_vm.booking.cancelledByBookingId,
      }},[_c('div',{staticClass:"col-auto"},[(_vm.isBookingOutbound)?_c('span',{staticClass:"badge my-2 bg-danger fs-6",attrs:{"title":"Ausgehende Buchung"}},[_c('span',{staticClass:"bi bi-box-arrow-left",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"px-1",attrs:{"aria-hidden":"true"}},[_vm._v("⁒")])]):_c('span',{staticClass:"badge my-2 bg-success fs-6",attrs:{"title":"Eingehende Buchung"}},[_c('span',{staticClass:"bi bi-box-arrow-in-right",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"bi bi-plus",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"col-auto font-monospace"},[_vm._v(" "+_vm._s(_vm.booking.createdAt.toLocaleString())+" ")]),_c('div',{staticClass:"w-100 d-block d-sm-none"}),_c('div',{staticClass:"col-auto vstack"},[_c('div',[_c('ProjectLabel',{staticClass:"fw-bold text-decoration-none text-reset",attrs:{"project-id":_vm.booking.getOppositeProjectId(_vm.projectId),"make-link":true}})],1),_c('div',{staticClass:"text-muted fst-italic"},[_vm._v(" "+_vm._s(_vm.booking.description)+" ")])]),_c('div',{staticClass:"col-auto text-end my-2"},[_c('BookingAmount',{attrs:{"booking":_vm.booking,"project-id":_vm.projectId}})],1)]),(_vm.booking.cancellation)?_c('div',{staticClass:"row border-top border-top-dashed align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"badge my-2 fs-6",class:{
            'bg-warning': _vm.isOutbound(_vm.booking.cancellation),
            'bg-info': !_vm.isOutbound(_vm.booking.cancellation),
          },attrs:{"title":_vm.isOutbound(_vm.booking.cancellation)
              ? 'Storno (ausgehende Rückbuchung)'
              : 'Storno (eingehende Rückbuchung)'}},[_c('span',{staticClass:"bi",class:{
              'bi-box-arrow-left': _vm.isOutbound(_vm.booking.cancellation),
              'bi-box-arrow-in-right': !_vm.isOutbound(_vm.booking.cancellation),
            },attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"bi bi-x-octagon",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"col-auto font-monospace"},[_vm._v(" "+_vm._s(new Date(_vm.booking.cancellation.updatedAt).toLocaleString())+" ")]),_c('div',{staticClass:"w-100 d-block d-sm-none"}),_c('div',{staticClass:"col text-muted fst-italic"},[_vm._v(" "+_vm._s(_vm.booking.cancellation.description)+" ")]),_c('div',{staticClass:"col"},[(_vm.booking.memberId === _vm.booking.cancellation.memberId)?_c('span',[_vm._v("Storniert durch die selbe Person")]):_c('span',[_c('MemberTile',{attrs:{"member":_vm.booking.cancellation.member,"size":32}})],1)])]):_vm._e(),(!_vm.isIoAccount)?_c('div',_vm._l((_vm.booking.fees),function(fee){return _c('section',{key:fee.id},[_c('div',{staticClass:"row border-top align-items-center justify-content-between",class:{
            'text-decoration-line-through': !!fee.cancelledByBookingId,
          }},[_vm._m(0,true),_c('div',{staticClass:"col-auto vstack"},[_c('div',[_c('ProjectLabel',{staticClass:"fw-bold text-decoration-none text-reset",attrs:{"project-id":_vm.isOutbound(fee) ? fee.targetProjectId : fee.sourceProjectId,"make-link":true}})],1),_c('div',{staticClass:"text-muted fst-italic"},[_vm._v(" "+_vm._s(fee.description)+" ")])]),_c('div',{staticClass:"col-auto text-end my-2"},[_c('BookingAmount',{attrs:{"booking":fee,"project-id":_vm.projectId}})],1)]),(fee.cancelledByBookingId)?_c('div',{staticClass:"row border-top border-top-dashed align-items-center justify-content-between"},[_c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"badge my-2 fs-6",class:{
                'bg-warning-subtle': _vm.isOutbound(fee.cancellation),
                'bg-info-subtle': !_vm.isOutbound(fee.cancellation),
              },attrs:{"title":_vm.isOutbound(fee.cancellation)
                  ? 'Entropie-Storno (ausgehende Rückbuchung)'
                  : 'Entropie-Storno (eingehende Rückerstattung)'}},[_c('span',{staticClass:"bi",class:{
                  'bi-box-arrow-up-right': _vm.isOutbound(fee.cancellation),
                  'bi-box-arrow-in-down-left': !_vm.isOutbound(fee.cancellation),
                },attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"bi bi-x-octagon",attrs:{"aria-hidden":"true"}})])]),_c('div',{staticClass:"col-auto font-monospace"},[_vm._v(" "+_vm._s(new Date(fee.cancellation.updatedAt).toLocaleString())+" ")]),_c('div',{staticClass:"w-100 d-block d-sm-none"}),_c('div',{staticClass:"col text-muted fst-italic"},[_vm._v(" "+_vm._s(fee.cancellation.description)+" ")]),_c('div',{staticClass:"col"},[(_vm.booking.memberId === fee.cancellation.memberId)?_c('span',[_vm._v("Storniert durch die selbe Person")]):_c('span',[_c('MemberTile',{attrs:{"member":fee.cancellation.member,"size":32}})],1)])]):_vm._e()])}),0):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-auto"},[_c('span',{staticClass:"badge my-2 bg-secondary fs-6",attrs:{"title":"Abgabe: Entropieumlage"}},[_c('span',{staticClass:"bi bi-box-arrow-up-right",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('span',{staticClass:"bi bi-percent",attrs:{"aria-hidden":"true"}})])])}]

export { render, staticRenderFns }