












import Vue from "vue";
import ProjectGridCard from "@/components/ProjectGridCard.vue";

export default Vue.extend({
  name: "ProjectGrid",
  components: { ProjectGridCard },
  props: {
    projects: {
      type: Array,
      required: true,
    },
    emptyListText: {
      type: String,
      default: "Keine Projekte gefunden",
    },
    showLatestBookings: {
      type: Number,
      default: 0,
    },
  },
});
