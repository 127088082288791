import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import HomeView from "../views/HomeView.vue";
import MembersView from "../views/MembersView.vue";
import PaymentView from "@/views/PaymentView.vue";
import ProjectDetailView from "@/views/ProjectDetailView.vue";
import ProjectsView from "@/views/ProjectsView.vue";
import ProjectEditView from "@/views/ProjectEditView.vue";
import MemberDetailsView from "@/views/MemberDetailsView.vue";
import CancellationView from "@/views/CancellationView.vue";
import StatsView from "@/views/StatsView.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/members",
    name: "members",
    component: MembersView,
  },
  {
    path: "/members/:memberId",
    name: "member-details",
    component: MemberDetailsView,
    props: (route) => {
      return {
        memberId:
          typeof route.params.memberId === "string"
            ? Number.parseInt(route.params.memberId)
            : route.params.memberId,
      };
    },
  },
  {
    path: "/projects",
    name: "projects",
    component: ProjectsView,
  },
  {
    path: "/projects/:projectId/edit",
    name: "project-editor",
    component: ProjectEditView,
    props: (route) => {
      return {
        projectId:
          route.params.projectId === "new"
            ? route.params.projectId
            : Number.parseInt(route.params.projectId),
      };
    },
  },
  {
    path: "/projects/:projectId",
    name: "project-details",
    component: ProjectDetailView,
    props: (route) => {
      return {
        projectId:
          typeof route.params.projectId === "string"
            ? Number.parseInt(route.params.projectId)
            : route.params.projectId,
      };
    },
  },
  {
    path: "/payments/",
    name: "payments",
    component: PaymentView,
    props: (route) => {
      return {
        prepopulatedAmount: Number(route.query.amount) || undefined,
        prepopulatedDescription: route.query.description,
        prepopulatedSourceProjectId:
          Number(route.query.sourceProjectId) || undefined,
        prepopulatedTargetProjectId:
          Number(route.query.targetProjectId) || undefined,
        prepopulatedDate: route.query.date,
      };
    },
  },
  {
    path: "/stats/",
    name: "stats",
    component: StatsView,
  },
  {
    path: "/cancel-payment/:bookingId",
    name: "cancel-payment",
    component: CancellationView,
    props: (route) => {
      return {
        bookingId:
          typeof route.params.bookingId === "string"
            ? Number.parseInt(route.params.bookingId)
            : route.params.bookingId,
      };
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active",
});

export default router;
