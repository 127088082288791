










































































































import Vue from "vue";
import BookingAmount from "@/components/BookingAmount.vue";
import ProjectLabel from "@/components/ProjectLabel.vue";
import MemberAmount from "@/components/MemberAmount.vue";
import MemberIcon from "@/components/MemberIcon.vue";
import ProjectBalance from "@/components/ProjectBalance.vue";

export default Vue.extend({
  name: "ProjectGridCard",
  components: {
    ProjectBalance,
    MemberIcon,
    BookingAmount,
    ProjectLabel,
    MemberAmount,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    showLatestBookings: {
      type: Number,
      default: 0,
    },
  },
});
