var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row row-cols-1 row-cols-xl-auto gap-1"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('div',{staticClass:"input-group"},[_vm._m(1),_c('input',{staticClass:"form-control",attrs:{"type":"search","placeholder":"Projekte filtern","aria-label":"Projekte filtern","aria-describedby":"search-button-addon"},on:{"input":_vm.updateSearchText}})])]),_c('div',{staticClass:"col"},[_c('SortingControls',{attrs:{"options":{
          pid: 'PID',
          name: 'Name',
          fee: 'Entropieumlage',
          createdAt: 'Erstellungsdatum',
          updatedAt: 'Änderungsdatum',
        }},model:{value:(_vm.sortObject),callback:function ($$v) {_vm.sortObject=$$v},expression:"sortObject"}})],1),_c('div',{staticClass:"col-4 col-md-2"},[_c('div',{staticClass:"btn-group",attrs:{"role":"group"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.view),expression:"view"}],staticClass:"btn-check",attrs:{"id":"btn-view-table","type":"radio","value":"table","autocomplete":"off"},domProps:{"checked":_vm._q(_vm.view,"table")},on:{"change":function($event){_vm.view="table"}}}),_c('label',{staticClass:"btn btn-outline-primary bi bi-table",attrs:{"for":"btn-view-table"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.view),expression:"view"}],staticClass:"btn-check",attrs:{"id":"btn-view-cards","type":"radio","value":"cards","autocomplete":"off"},domProps:{"checked":_vm._q(_vm.view,"cards")},on:{"change":function($event){_vm.view="cards"}}}),_c('label',{staticClass:"btn btn-outline-primary bi bi-view-list",attrs:{"for":"btn-view-cards"}})])]),_c('div',{staticClass:"col-3 col-md-2 col-xl-1"},[_c('button',{staticClass:"btn btn-outline-secondary",attrs:{"type":"button","title":"Alle Projekte als CSV exportieren"},on:{"click":function($event){$event.preventDefault();return _vm.exportProjectsOverview.apply(null, arguments)}}},[_c('span',{staticClass:"bi bi-box-arrow-down",attrs:{"aria-hidden":"true"}}),_vm._v(" CSV ")])]),_c('div',{staticClass:"col-6 col-xl-2"},[_c('RouterLink',{staticClass:"btn btn-secondary",attrs:{"to":{ name: 'project-editor', params: { projectId: 'new' } }}},[_c('span',{staticClass:"bi bi-plus-lg"}),_vm._v(" Neues Projekt ")])],1)]),_c('FeathersVuexPagination',{staticClass:"m-3",attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentPage = ref.currentPage;
      var pageCount = ref.pageCount;
      var next = ref.next;
      var prev = ref.prev;
      var toPage = ref.toPage;
      var toStart = ref.toStart;
      var toEnd = ref.toEnd;
      var canNext = ref.canNext;
      var canPrev = ref.canPrev;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('PaginationControls',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"next":next,"prev":prev,"page":toPage,"to-start":toStart,"to-end":toEnd}})],1)]}}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}}),(_vm.searchError)?_c('MessageBox',{attrs:{"message":_vm.searchError}}):(_vm.isSearchPending)?_c('LoadingSpinner'):[(_vm.view === 'table')?_c('ProjectTable',{attrs:{"projects":_vm.projects,"sort-object":_vm.sortObject}}):_c('ProjectGrid',{staticClass:"row-cols-md-2 row-cols-lg-3",attrs:{"projects":_vm.projects}})],_c('FeathersVuexPagination',{staticClass:"mt-3",attrs:{"latest-query":_vm.latestQuery},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var currentPage = ref.currentPage;
      var pageCount = ref.pageCount;
      var next = ref.next;
      var prev = ref.prev;
      var toPage = ref.toPage;
      var toStart = ref.toStart;
      var toEnd = ref.toEnd;
      var canNext = ref.canNext;
      var canPrev = ref.canPrev;
return [_c('div',{staticClass:"d-flex justify-content-center"},[_c('PaginationControls',{attrs:{"current-page":currentPage,"page-count":pageCount,"can-prev":canPrev,"can-next":canNext},on:{"next":next,"prev":prev,"page":toPage,"to-start":toStart,"to-end":toEnd}})],1)]}}]),model:{value:(_vm.pagination),callback:function ($$v) {_vm.pagination=$$v},expression:"pagination"}})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-2"},[_c('h1',[_vm._v("Projekte")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"input-group-text",attrs:{"id":"search-button-addon"}},[_c('span',{staticClass:"bi bi-search"})])}]

export { render, staticRenderFns }