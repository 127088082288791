import feathers, { HookContext } from "@feathersjs/feathers";
import socketio from "@feathersjs/socketio-client";
import auth from "@feathersjs/authentication-client";
import io from "socket.io-client";
import { iff, discard } from "feathers-hooks-common";
import feathersVuex from "feathers-vuex";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const config = window.koleConfig || {};
const apiUrl = config?.apiBaseUrl || "/";
console.log("API URL is", apiUrl);

const socket = io(apiUrl, { transports: ["websocket"] });

const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth({ storage: window.localStorage, storageKey: "kole-jwt" }))
  .hooks({
    before: {
      all: [
        iff(
          (context: HookContext) =>
            ["create", "update", "patch"].includes(context.method),
          discard("__id", "__isTemp"),
        ),
      ],
    },
  });

export default feathersClient;

const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } =
  feathersVuex(feathersClient, {
    serverAlias: "api",
    idField: "id",
    whitelist: ["$regex", "$options"],
    paramsForServer: ["$eager"],
  });

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex };
