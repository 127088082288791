import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from "../../feathers-client";
import {
  AnyData,
  GlobalModels,
  ModelInstanceOptions,
  ModelSetupContext,
} from "feathers-vuex/dist/service-module/types";

class Booking extends BaseModel {
  constructor(data: AnyData, options: ModelInstanceOptions) {
    super(data, options);
  }

  getOppositeProjectId(myProjectId: number): number {
    const isBookingOutbound = myProjectId === this.sourceProjectId;
    return isBookingOutbound ? this.targetProjectId : this.sourceProjectId;
  }

  static modelName = "Booking";

  static instanceDefaults() {
    return {
      amount: 0,
      description: "",
      feeForBookingId: null,
      sourceProjectId: undefined,
      targetProjectId: undefined,
    };
  }

  static setupInstance(data: AnyData, { models }: ModelSetupContext) {
    // Convert date strings into Date objects
    for (const prop of ["createdAt", "updatedAt"]) {
      if (data[prop]) {
        data[prop] = new Date(data[prop]);
      }
    }

    // Add nested project objects to the store, if they are unknown
    const ProjectModel = models.api.Project;
    if (data.source && !ProjectModel.getFromStore(data.source.id)) {
      new ProjectModel(data.source);
    }
    if (data.target && !ProjectModel.getFromStore(data.target.id)) {
      new ProjectModel(data.target);
    }

    return data;
  }
}

const servicePath = "bookings";
const servicePlugin = makeServicePlugin({
  Model: Booking,
  service: feathersClient.service(servicePath),
  servicePath,
  handleEvents: {
    async created(booking: AnyData, { models }: { models: GlobalModels }) {
      // When a booking gets created, refresh the related projects
      try {
        if (booking.sourceProjectId !== 0) {
          await models.api.Project.get(booking.sourceProjectId);
        }
        if (booking.targetProjectId !== 0) {
          await models.api.Project.get(booking.targetProjectId);
        }
      } catch (e) {
        console.warn("Error while refreshing projects", e);
      }
    },
  },
});

// Client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

export default servicePlugin;
