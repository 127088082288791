








import Vue from "vue";

export default Vue.extend({
  name: "MessageBox",
  props: {
    message: {
      type: [Object, Error, String],
      default: undefined,
    },
    color: {
      type: String,
      default: "danger",
    },
  },
  computed: {
    colorClass(): string {
      switch (this.color) {
        case "primary":
          return "alert-primary";
        case "secondary":
          return "alert-secondary";
        case "success":
          return "alert-success";
        case "warning":
          return "alert-warning";
        case "info":
          return "alert-info";
        case "light":
          return "alert-light";
        case "dark":
          return "alert-dark";

        default:
          return "alert-danger";
      }
    },
    messages(): string[] {
      if (this.message instanceof Error) {
        return [this.message.message];
      } else if (typeof this.message === "string") {
        return [this.message];
      }

      if (this.message.type === "FeathersError") {
        if (this.message.errors.length > 0) {
          return this.message.errors.map(
            (error: Record<string, unknown>) => error.message,
          );
        } else {
          return [this.message.message];
        }
      } else if (typeof this.message.message === "string") {
        let messages = [this.message.message];
        if (this.message.errors instanceof Array) {
          messages = messages.concat(
            this.message.errors.map((value: any) => String(value)),
          );
        }
        return messages;
      }

      return [this.message.toString()];
    },
  },
});
