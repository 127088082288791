












import Vue from "vue";
import ProjectLabel from "@/components/ProjectLabel.vue";
import GeneralAmount from "@/components/GeneralAmount.vue";

export default Vue.extend({
  name: "BookingSummary",
  components: { GeneralAmount, ProjectLabel },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
});
