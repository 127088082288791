





















































import Vue from "vue";
import MessageBox from "@/components/MessageBox.vue";
import ProjectForm from "@/components/ProjectForm.vue";

export default Vue.extend({
  name: "ProjectEditView",
  components: { MessageBox, ProjectForm },
  props: {
    projectId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    const { Project } = this.$FeathersVuex.api;
    return {
      formError: null,
      newItem: new Project({ memberIds: [] }),
    };
  },
  computed: {
    item(): Record<string, unknown> {
      const { Project } = this.$FeathersVuex.api;
      // Get the Project for the given ID or create a new one if the ID is 'new'
      return this.projectId === "new"
        ? this.newItem
        : Project.getFromStore(this.projectId);
    },
    title(): string {
      return this.projectId === "new"
        ? "Projekt anlegen"
        : "Projekt bearbeiten";
    },
  },
  watch: {
    projectId: {
      handler(value) {
        if (value === "new") {
          return;
        }
        const { Project } = this.$FeathersVuex.api;
        const existingRecord = Project.getFromStore(value);

        // If the record was not in the store, we have to fetch it from the server
        if (!existingRecord) {
          Project.get(value);
        }
      },
      immediate: true,
    },
  },
});
