










import Vue from "vue";
import GeneralAmount from "@/components/GeneralAmount.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend({
  name: "ProjectBalance",
  components: { LoadingSpinner, GeneralAmount },
  props: {
    projectId: {
      type: Number,
      required: true,
    },
    reportingDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      balance: undefined,
      error: false,
      isGetPending: true,
    };
  },
  async created() {
    this.isGetPending = true;
    try {
      const payload: { projectId: number; reportingDate?: string } = {
        projectId: this.projectId,
      };
      if (this.reportingDate) {
        payload.reportingDate = this.reportingDate;
      }
      const balance = await this.$store.dispatch("getProjectBalance", payload);
      this.balance = balance.balance;
    } catch (e) {
      this.error = true;
      console.error(e);
    } finally {
      this.isGetPending = false;
    }
  },
});
