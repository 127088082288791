










import Vue from "vue";

export default Vue.extend({
  name: "GeneralAmount",
  props: {
    amount: { type: Number, required: true },
  },
  computed: {
    isNegative(): boolean {
      return this.amount < 0;
    },
    cssClass(): string {
      if (this.amount < 0) {
        return "text-danger bg-danger bg-opacity-10";
      } else if (this.amount === 0) {
        return "text-body-tertiary bg-body-tertiary bg-opacity-10";
      }
      return "text-body bg-primary bg-opacity-10";
    },
    formattedAmount(): string {
      return this.amount.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  },
});
