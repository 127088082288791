























































import Vue from "vue";

export default Vue.extend({
  name: "MemberTable",
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    sortObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: {
        chiffre: { name: "Chiffre", sortable: true },
        name: { name: "Nickname", sortable: true },
        email: { name: "Kontakt", sortable: false },
        projects: { name: "Projekte", sortable: false },
      },
    };
  },
});
