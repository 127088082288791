









import Vue from "vue";

export default Vue.extend({
  name: "ProjectLabel",
  props: {
    projectId: { type: Number, required: true },
    makeLink: { type: Boolean, default: false },
  },
  computed: {
    projectName(): string {
      if (this.projectId === 0) {
        return "[NOWHERE]";
      }

      // Get the project from the local state
      const project = this.$store.getters["projects/get"](this.projectId);
      if (project && project.name) {
        return project.name;
      }

      return `Projekt #${this.projectId}`;
    },
  },
});
