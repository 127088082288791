import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from "../../feathers-client";
import {
  AnyData,
  ModelInstanceOptions,
  ModelSetupContext,
} from "feathers-vuex/dist/service-module/types";
import { HookContext } from "@feathersjs/feathers";

class Member extends BaseModel {
  constructor(data: AnyData, options: ModelInstanceOptions) {
    super(data, options);
  }

  static modelName = "Member";

  static instanceDefaults() {
    return {
      chiffre: 0,
      email: "",
      name: "",
    };
  }

  static setupInstance(data: AnyData, { models, store }: ModelSetupContext) {
    // Convert date strings into Date objects
    for (const prop of ["createdAt", "updatedAt"]) {
      if (data[prop]) {
        data[prop] = new Date(data[prop]);
      }
    }

    // Add nested project objects to the store
    if (!data.projectIds && data.projects && Array.isArray(data.projects)) {
      data.projectIds = data.projects.map((project: AnyData) => {
        // We already insert/update the member record on this level
        // No need to pass the nested members down with the project
        // Also, the nested members would overwrite the existing ones
        // Still, pass down the member IDs, so the `members` getter works
        const projectMembers = project.members || [];
        project.memberIds = projectMembers.map((member: AnyData) => {
          return member.id;
        });
        delete project.members;

        new models.api.Project(project);
        return project.id;
      });
    }

    if (data.projectIds) {
      store.dispatch("memberships/updateMemberMemberships", {
        memberId: data.id,
        projectIds: data.projectIds,
      });
    }

    // Replace the nested projects with a getter
    Object.defineProperty(data, "projects", {
      get: function () {
        const projects = models.api.Project.findInStore({
          query: {
            id: {
              $in: store.getters["memberships/projectIdsForMemberId"](data.id),
            },
          },
          paginate: false,
        });
        return projects.data;
      },
      configurable: true,
      enumerable: true,
    });

    return data;
  }
}

const servicePath = "members";
const servicePlugin = makeServicePlugin({
  Model: Member,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [
      (context: HookContext) => {
        // When getting a single member, always request the nested projects
        context.params.query = Object.assign(context.params.query || {}, {
          $eager: "projects.members",
        });
        return context;
      },
    ],
    create: [prepareDataForCreateUpdate],
    update: [prepareDataForCreateUpdate],
    patch: [prepareDataForCreateUpdate],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

function prepareDataForCreateUpdate(context: HookContext): HookContext {
  delete context.data.projectIds;
  return context;
}

export default servicePlugin;
