























































import Vue from "vue";
import MessageBox from "@/components/MessageBox.vue";
import MemberIcon from "@/components/MemberIcon.vue";
import ProjectGrid from "@/components/ProjectGrid.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default Vue.extend({
  name: "MemberDetailsView",
  components: {
    MessageBox,
    MemberIcon,
    ProjectGrid,
    LoadingSpinner,
  },
  props: {
    memberId: {
      type: Number,
      required: true,
    },
  },
});
