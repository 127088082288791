


























import Vue from "vue";
import MemberTile from "@/components/MemberTile.vue";

export default Vue.extend({
  name: "ProjectMemberList",
  components: {
    MemberTile,
  },
  props: {
    members: {
      type: Array,
      required: true,
    },
  },
});
