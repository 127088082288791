


































































import Vue from "vue";
import ProjectLabel from "@/components/ProjectLabel.vue";
import BookingAmount from "@/components/BookingAmount.vue";

export default Vue.extend({
  name: "BookingListLine",
  components: { ProjectLabel, BookingAmount },
  props: {
    booking: { type: Object, required: true },
    projectId: { type: Number, required: true },
  },
  computed: {
    isBookingOutbound(): boolean {
      return this.projectId === this.booking.sourceProjectId;
    },
    otherProjectId(): number {
      return this.isBookingOutbound
        ? this.booking.targetProjectId
        : this.booking.sourceProjectId;
    },
    isCancellable(): boolean {
      return !(
        this.booking.cancellationFor.length || this.booking.cancelledByBookingId
      );
    },
  },
});
