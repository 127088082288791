







import Vue from "vue";

export default Vue.extend({
  name: "LoadingSpinner",
  props: {
    text: {
      type: String,
      default: "Lade Daten...",
    },
  },
});
