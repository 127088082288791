var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',_vm._l((Object.entries(_vm.columns)),function(ref){
var columnId = ref[0];
var column = ref[1];
return _c('th',{key:columnId,staticClass:"text-nowrap",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(column.name)+" "),(_vm.sortObject.hasOwnProperty(columnId))?_c('span',{class:{
            'bi-sort-down-alt': _vm.sortObject[columnId] === 1,
            'bi-sort-down': _vm.sortObject[columnId] === -1,
          }}):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.members),function(member){return _c('tr',{key:member.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(member.chiffre))]),_c('td',[_c('RouterLink',{attrs:{"to":{ name: 'member-details', params: { memberId: member.id } }}},[_vm._v(" "+_vm._s(member.name)+" ")])],1),_c('td',{staticClass:"text-nowrap"},[_c('i',{staticClass:"bi bi-envelope",attrs:{"aria-hidden":"true"}}),_vm._v(" "),_c('a',{attrs:{"href":'mailto:' + member.email}},[_vm._v(_vm._s(member.email))])]),_c('td',_vm._l((member.projects),function(project){return _c('RouterLink',{key:project.id,staticClass:"btn btn-sm btn-outline-secondary me-2 mb-2 text-nowrap",attrs:{"to":{
            name: 'project-details',
            params: { projectId: project.id },
          }}},[_vm._v(" "+_vm._s(project.name)+" ")])}),1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }