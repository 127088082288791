









































import MessageBox from "@/components/MessageBox.vue";
import Vue from "vue";

export default Vue.extend({
  name: "LoginView",
  components: {
    MessageBox,
  },
  data() {
    return {
      username: "",
      loginError: null,
      password: "",
    };
  },
  computed: {
    errorToShow(): Record<string, unknown> | undefined {
      const errorToShow = this.loginError
        ? this.loginError
        : this.$store.state.auth.errorOnAuthenticate;

      if (errorToShow) {
        if (
          errorToShow.message === "No accessToken found in storage" ||
          errorToShow.message.includes("expired")
        ) {
          // Not really an error, there is just no valid session token in local storage
          return undefined;
        } else if (errorToShow.message === "Invalid login") {
          errorToShow.message = "Zugangsdaten ungültig";
        } else if (errorToShow.message.includes("Timeout")) {
          errorToShow.message = "Zeitüberschreitung";
        }
      }

      return errorToShow;
    },
    isFormValid(): boolean {
      return this.username !== "" && this.password !== "";
    },
    isLoginDisabled(): boolean {
      return !this.isFormValid || this.isLoginPending;
    },
    isLoginPending(): boolean {
      return this.$store.state.auth.isAuthenticatePending;
    },
  },
  methods: {
    login: function () {
      this.loginError = null;

      // Differentiate auth strategy by assuming everything with an @ is an email
      const payload = this.username.includes("@")
        ? {
            email: this.username,
            password: this.password,
            strategy: "local",
          }
        : {
            username: this.username,
            password: this.password,
            strategy: "ldap",
          };

      this.$store.dispatch("auth/authenticate", payload).catch((reason) => {
        this.loginError = reason;
      });
    },
  },
});
