











































import Vue from "vue";
import ProjectMemberGrid from "@/components/ProjectMemberGrid.vue";
import ProjectBalance from "@/components/ProjectBalance.vue";

export default Vue.extend({
  name: "ProjectTable",
  components: {
    ProjectBalance,
    ProjectMemberGrid,
  },
  props: {
    projects: {
      type: Array,
      default: () => [],
    },
    sortObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      columns: {
        pid: { name: "PID", sortable: true },
        name: { name: "Name", sortable: true },
        description: { name: "Beschreibung", sortable: false },
        members: { name: "Member", sortable: false },
        fee: { name: "Entropieumlage", sortable: true },
        balance: { name: "Kontostand", sortable: false },
      },
    };
  },
});
