
































import Vue from "vue";
import BookingSummary from "@/components/BookingSummary.vue";

export default Vue.extend({
  name: "PaymentSuccessMessage",
  components: { BookingSummary },
  props: {
    payment: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onCounterbalanceIo(event: any) {
      event.target.disabled = true;
      this.$emit("counterbalance-io", this.payment);
    },
  },
});
