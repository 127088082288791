
















import Vue from "vue";
import VueTypeaheadBootstrap from "vue-typeahead-bootstrap";
import MemberTile from "@/components/MemberTile.vue";

export default Vue.extend({
  name: "MemberSelector",
  components: { VueTypeaheadBootstrap, MemberTile },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      members: [],
      query: "",
    };
  },
  methods: {
    async lookupMembers() {
      const selectedMemberIds = this.value.map((member: any) => member.id);
      const result = await this.$store.dispatch("members/find", {
        query: {
          $or: [
            { name: { $like: `%${this.query}%` } },
            { chiffre: { $like: `%${this.query}%` } },
          ],
          id: { $nin: selectedMemberIds },
        },
      });
      this.members = result.data || [];
    },
    memberSelected(event: Record<string, unknown>) {
      this.$emit("input", this.value.concat([event]));
      this.query = "";
    },
  },
});
