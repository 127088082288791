var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"table"},[_c('thead',[_c('tr',_vm._l((Object.entries(_vm.columns)),function(ref){
var columnId = ref[0];
var column = ref[1];
return _c('th',{key:columnId,attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(column.name)+" "),(_vm.sortObject.hasOwnProperty(columnId))?_c('span',{class:{
            'bi-sort-down-alt': _vm.sortObject[columnId] === 1,
            'bi-sort-down': _vm.sortObject[columnId] === -1,
          }}):_vm._e()])}),0)]),_c('tbody',_vm._l((_vm.projects),function(project){return _c('tr',{key:project.id},[_c('th',{attrs:{"scope":"row"}},[_vm._v(_vm._s(project.pid))]),_c('td',[_c('RouterLink',{attrs:{"to":{
            name: 'project-details',
            params: { projectId: project.id },
          }}},[_vm._v(_vm._s(project.name))])],1),_c('td',[_vm._v(_vm._s(project.description))]),_c('td',[_c('ProjectMemberGrid',{attrs:{"members":project.members,"max":5,"size":20}})],1),_c('td',{staticClass:"text-end"},[_vm._v(_vm._s(project.fee)+" %")]),_c('td',{staticClass:"text-end"},[_c('ProjectBalance',{attrs:{"project-id":project.id}})],1)])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }