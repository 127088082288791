import feathersClient, {
  makeServicePlugin,
  BaseModel,
} from "../../feathers-client";
import {
  AnyData,
  ModelInstanceOptions,
  ModelSetupContext,
} from "feathers-vuex/dist/service-module/types";
import { HookContext } from "@feathersjs/feathers";

class Project extends BaseModel {
  constructor(data: AnyData, options: ModelInstanceOptions) {
    super(data, options);
  }

  static modelName = "Project";

  static instanceDefaults() {
    return {
      description: "",
      fee: 20,
      name: "",
      pid: "",
      members: [],
    };
  }

  static setupInstance(data: AnyData, { models, store }: ModelSetupContext) {
    // Convert date strings into Date objects
    for (const prop of ["createdAt", "updatedAt"]) {
      if (data[prop]) {
        data[prop] = new Date(data[prop]);
      }
    }

    // Add nested member objects to the store
    if (!data.memberIds && data.members && Array.isArray(data.members)) {
      data.memberIds = data.members.map((member) => {
        // We already insert/update the project record on this level
        // No need to pass the nested projects down with the member
        // Also, the nested projects would overwrite the existing ones
        // Still, pass down the project IDs, so the `projects` getter works
        const memberProjects = member.projects || [];
        member.projectIds = memberProjects.map((project: AnyData) => {
          return project.id;
        });
        delete member.projects;

        new models.api.Member(member);
        return member.id;
      });
    }

    if (data.memberIds) {
      store.dispatch("memberships/updateProjectMemberships", {
        projectId: data.id,
        memberIds: data.memberIds,
      });
    }

    // Replace the nested members with a getter
    Object.defineProperty(data, "members", {
      get: function () {
        const members = models.api.Member.findInStore({
          query: {
            id: {
              $in: store.getters["memberships/memberIdsForProjectId"](data.id),
            },
          },
          paginate: false,
        });
        return members.data;
      },
      configurable: true,
      enumerable: true,
    });

    return data;
  }
}

const servicePath = "projects";
const servicePlugin = makeServicePlugin({
  Model: Project,
  service: feathersClient.service(servicePath),
  servicePath,
});

// Client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [
      (context: HookContext) => {
        // When getting a single project, always request the nested members
        context.params.query = Object.assign(context.params.query || {}, {
          $eager: "members.projects",
        });
        return context;
      },
    ],
    create: [prepareDataForCreateUpdate],
    update: [prepareDataForCreateUpdate],
    patch: [prepareDataForCreateUpdate],
    remove: [],
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: [],
  },
});

/**
 * Remove unnecessary properties from the data before sending it.
 *
 * @param {HookContext} context
 * @return {HookContext}
 */
function prepareDataForCreateUpdate(context: HookContext): HookContext {
  delete context.data.createdAt;
  delete context.data.updatedAt;
  delete context.data.memberIds;
  context.data.members = context.data.members.map((member: any) => {
    return { id: member.id };
  });

  return context;
}

export default servicePlugin;
