

























































































































































































































































import Vue from "vue";
import ProjectLabel from "@/components/ProjectLabel.vue";
import BookingAmount from "@/components/BookingAmount.vue";
import MemberTile from "@/components/MemberTile.vue";
import GeneralAmount from "@/components/GeneralAmount.vue";

export default Vue.extend({
  name: "BookingCard",
  components: { ProjectLabel, BookingAmount, MemberTile, GeneralAmount },
  props: {
    booking: { type: Object, required: true },
    projectId: { type: Number, required: true },
  },
  computed: {
    isBookingOutbound(): boolean {
      return this.projectId === this.booking.sourceProjectId;
    },
    otherProjectId(): number {
      return this.isBookingOutbound
        ? this.booking.targetProjectId
        : this.booking.sourceProjectId;
    },
    isCancellable(): boolean {
      return !(
        this.booking.cancellationFor.length || this.booking.cancelledByBookingId
      );
    },
    isIoAccount(): boolean {
      return this.projectId == this.$store.state.ioPid;
    },
    totalAmount(): number {
      let sum = 0;
      if (!this.booking.cancelledByBookingId) {
        sum += this.booking.amount;
      }
      if (this.isBookingOutbound && sum > 0) {
        sum *= -1;
      }
      for (const fee of this.booking.fees ?? []) {
        if (fee.cancelledByBookingId) {
          continue;
        }
        sum +=
          fee.sourceProjectId === this.projectId ? -fee.amount : fee.amount;
      }
      return sum;
    },
  },
  methods: {
    isOutbound(booking: any): boolean {
      return this.projectId === booking.sourceProjectId;
    },
  },
});
